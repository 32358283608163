import { Area } from './area';
import { HrefLink } from '../interface/generic-links';
import { Hateoas } from './hateos';

export class ServiceRoom extends Hateoas<ServiceRoomLinks> {
    name: string;
    description: string;
    area: string | Area;
}

export interface ServiceRoomLinks {
    area: HrefLink;
}

export interface ServiceRoomGetList {
    serviceRooms: ServiceRoom[];
}