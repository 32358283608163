import { Hateoas } from './hateos';
import { ServiceRoom } from './service-room';
import { Area } from './area';
import { HrefLink } from '../interface/generic-links';
import { Principal } from 'dst-security/lib/models/principal';
import { Language } from './language';

export class Operator extends Hateoas<OperatorLinks>{
    name: string;
    surname: string;
    serviceRoom: string | ServiceRoom;
    serviceRoomId: number;
    area:Area;
    principal: string | Principal;
    principalId:number;
    code:string;
    jobTitle: string;
    languages: Language[];
}

export interface OperatorLinks {
    principal: HrefLink;
    serviceRoom: HrefLink;
    languages: HrefLink;
}

export interface OperatorGetList {
    operators: Operator[];
} 