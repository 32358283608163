import { Injectable } from '@angular/core';
import {throwError, Observable, from, Subject, BehaviorSubject} from 'rxjs';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  
  public readonly PHONEREGEX = '^[+]*[(]{0,1}[0-9]{1,5}[)]{0,1}[0-9]{4,20}$';
  private statusChangedSource = new Subject<any>();
  statusChangedConfirmed = this.statusChangedSource.asObservable();
  static snackBar: MatSnackBar;

  private loaderBar = new BehaviorSubject({isEnabled: false, counter: 0});

  constructor() { }

  public static getIdBySelfHref(selfHref) {
    let index = selfHref.lastIndexOf("/");
    let id = '-1';
    if (index != -1) id = selfHref.substring(index + 1, selfHref.length);
    return Number(id);
  }

  static showMessage(message, type?) {
    type = type != null ? type : 'Info';
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 4000,
      panelClass: ['snackBar'],
      data: { message: message, snackType: type }
    });

  }

  statusChanged() {
    this.statusChangedSource.next(true);
  }


  startLoaderBar() {
    this.loaderBar.next({
      isEnabled: true,
      counter: this.loaderBar.value.counter + 1
    });
  }

  stopLoaderBar(reset = false) {
    this.loaderBar.next({
      isEnabled: (this.loaderBar.value.counter <= 1 || reset === false) ? false : true,
      counter: (this.loaderBar.value.counter <= 1 || reset === false) ? this.loaderBar.value.counter - 1 : 0
    });
  }

  getStatusLoaderBar(): BehaviorSubject<any>{
    return this.loaderBar;
  }


}
