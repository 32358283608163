import { Injectable } from '@angular/core';
import { HateoasService } from './hateoas.service';
import { HttpClient } from '@angular/common/http';
import { Desk, DeskGetList } from '../model/desk';
import { environment } from 'src/environments/environment';
import { GetList } from '../interface/generic-links';
import { Subject, Observable} from 'rxjs';
import { PrincipalService } from 'dst-security';
import { Router } from '@angular/router';
import { Agenda } from '../model/agenda';
import { ServiceRoom } from '../model/service-room';
import { ServiceRoomService } from './service-room.service';

const MY_DESK_KEY = 'MY_DESK';
const deskBaseUrl = `${environment.endpointUri}/desks`;
const deskControllerUrl = `${environment.endpointUri}/desk`;
const freeDeskUrl = `${deskBaseUrl}/search/findByActiveOperatorIsNullAndServiceRoomIdAndCompanyId?sort=name,asc&size=50&serviceRoomId=`;
const agendaControllerUrl = `${environment.endpointUri}/agenda`;

const myDeskObs = new Subject<Desk>();

// const agendaObs = new Subject<string>();



@Injectable({
  providedIn: 'root'
})
export class DeskService extends HateoasService {

  private myDesk: Desk;

  constructor(http: HttpClient, public router: Router, private principalService: PrincipalService, private serviceRoomService: ServiceRoomService) { super(http); }

  clearAgenda() {
    return this.http.put(`${agendaControllerUrl}/clear`, {});
  }

  refreshAgenda(desk:Desk, serviceRoom: ServiceRoom): Observable<Agenda> {
    let serviceRoomId = (environment.operatorViewAnyServiceRoom) ? '' : serviceRoom.id;
    return this.http.put<Agenda>(`${deskControllerUrl}/engage?deskCode=${desk.code}&serviceRoomId=${serviceRoomId}&refresh=true`, {});
  } 

  public subscribeDeskChange(observer) {
    myDeskObs.subscribe(observer);
  }

  // public subscribeAgendaChange(observer) {
  //   agendaObs.subscribe(observer);
  // }

  public getStoredDesk(): Desk {
    return this.myDesk ? this.myDesk : JSON.parse(localStorage.getItem(MY_DESK_KEY));
  }

  public setStoredDesk(desk: Desk) {
    this.myDesk = desk;
    localStorage.setItem(MY_DESK_KEY, JSON.stringify(desk));
    myDeskObs.next(desk);
  }

  freeDesk() {
    let desk: Desk = this.getStoredDesk();
    this.freeDeskBySelf(desk).subscribe(
      result => {
        console.debug(result);
        localStorage.removeItem(MY_DESK_KEY);
        this.myDesk = null;
      }
    );
  }

  freeDeskBySelf(desk:Desk) {
    return this.http.patch(desk._links.self.href, { activeOperator: null })
  }

  freeAgenda(deskId: number) {
    const url = `${agendaControllerUrl}/clearAgenda?deskId=${deskId}`;
    return this.http.put<any>(url, {});
  }

  freeDeskAndLogOut() {
    let desk: Desk = this.getStoredDesk();
    if (desk && desk._links) {
      this.http.patch(desk._links.self.href, { activeOperator: null }).subscribe(
        result => {
          console.debug(result);

          if (localStorage.getItem('preprintSchedules') != null) {
            var myItem = localStorage.getItem('preprintSchedules');
            localStorage.clear();
            localStorage.setItem('preprintSchedules', myItem);
          }else{
            localStorage.clear();
          }
          this.setStoredDesk(null);
          this.serviceRoomService.setStoredServiceRoom(null);
          this.router.navigate(['security/login']);
        }
      );
    } else {

      if (localStorage.getItem('preprintSchedules') != null) {
        var myItem = localStorage.getItem('preprintSchedules');
        localStorage.clear();
        localStorage.setItem('preprintSchedules', myItem);
      }else{
        localStorage.clear();
      }

      this.router.navigate(['security/login']);
    }
  }

  listFreeDesks(serviceRoomId: number, companyId: number) {
    if(serviceRoomId && companyId){
      let url = `${freeDeskUrl}${serviceRoomId}&companyId=${companyId}`;
      return this.get<GetList<DeskGetList>>(url);
    }
  }

}
