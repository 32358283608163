import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Language } from 'src/app/model/language';
import { LanguageService } from  '../../../services/language.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit, OnChanges {

  languages: Language[] = [];
  @Input() value: Language | Language[];
  @Input() multiple: boolean = false;
  @Input() redTheme: boolean = false;
  @Output() selected: EventEmitter<Language> = new EventEmitter<Language>();

  constructor(private langService: LanguageService) { }

  ngOnInit() {
    this.getLanguages();
  }

  ngOnChanges() {
    if (this.languages.length > 0 && this.multiple) this.setLanguages();
  }

  getLanguages() {
    this.langService.getLangaugesFromBe().subscribe( res => {
      this.languages = res._embedded.languages;
      if (this.value) {
        this.setLanguages();
      }
    });
  }

  setLanguages() {
    let selectedLanguage: Language[] = this.value as Language[];
    this.value = [];
    for (let i = 0; i < selectedLanguage.length; i++) {
      let index = this.languages.findIndex(x => x.code == selectedLanguage[i].code);
      if (index > -1) {
        this.value.push(this.languages[index]);
      }
    }
   
  }

  onChange(event) {
    this.selected.emit(event.target.value);
  }

}
