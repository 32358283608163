import { Injectable } from '@angular/core';
import { HateoasService } from './hateoas.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { ServiceRoom } from '../model/service-room';
import { Constants } from '../constantsEndpoint';
import { OperatorService } from './operator.service';

const myServiceRoomObs = new Subject<ServiceRoom>();
const ACTIVE_SR_KEY = "ACTIVE_SR";

const SERVICE_ROOM_BASE_URL = `${environment.endpointUri}/serviceRooms`;
@Injectable({
  providedIn: 'root'
})
export class ServiceRoomService extends HateoasService {
  private myServiceRoom: ServiceRoom;

  constructor(http: HttpClient) { super(http) }
  
  public getStoredServiceRoom(): ServiceRoom {
    return this.myServiceRoom ? this.myServiceRoom : JSON.parse(localStorage.getItem(ACTIVE_SR_KEY));
  }

  public setStoredServiceRoom(serviceRoom: ServiceRoom) {
    this.myServiceRoom = serviceRoom;
    localStorage.setItem(ACTIVE_SR_KEY, JSON.stringify(serviceRoom));
    myServiceRoomObs.next(serviceRoom);
  }

  public subscribeServiceRoomChange(observer) {
    myServiceRoomObs.subscribe(observer);
  }

  public findById(id: number): Observable<ServiceRoom> {
    if (id){
      return this.get<ServiceRoom>(`${SERVICE_ROOM_BASE_URL}/${id}`);
    }
  }

}
