import { Injectable } from '@angular/core';
import { HateoasService } from './hateoas.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constantsEndpoint';
import { Company } from '../model/company';
import { Subject } from 'rxjs/internal/Subject';
import { Operator } from '../model/operator';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends HateoasService{
  private companyList = new Subject();


  constructor(http: HttpClient) {
    super(http);
  }

  public selectedCompanySource = new Subject<string>();
  public selectedCompanyConfirmed = this.selectedCompanySource.asObservable();

  public loginSource = new Subject<Operator>();
  public loginConfirmed = this.loginSource.asObservable();

  getActiveCompany(): Company {
    let companyStr:string =localStorage.getItem(Constants.ACTIVE_COMPANY_KEY);
    if(companyStr==null || companyStr.length<1)
      return null;
    return new Company(JSON.parse(companyStr));
  }

  getCompanyList(){
    return this.companyList;
  }

  setCompanyList(companyList: Company[]) {
    this.companyList.next(companyList);
  }

  setOperatorCompanyActive(operatorId, activeCompanyLink){
    if(operatorId){
      let url = `${environment.endpointUri}/operators/${operatorId}`;
      return this.http.patch(url, {activeCompany: activeCompanyLink});
    }
  }
}
