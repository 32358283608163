import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '../interface/page';
import { environment } from 'src/environments/environment';
import { HateoasService } from './hateoas.service';
import { OperatorInactivityEvent, OperatorInactivityEventList } from '../model/operator-inactivity-event';
import { GetList } from '../interface/generic-links';

const OIE_BASE_URL = `${environment.endpointUri}/operatorInactivityEvents`;
const FIND_BY_SERVICE_ROOM_ID_URL = `${OIE_BASE_URL}/search/findByLastAction_Operator_ServiceRoom_Id?projection=OperatorInactivityEventData`;

@Injectable({
  providedIn: 'root'
})
export class OperatorInactivityService extends HateoasService {

  constructor(http: HttpClient) { super(http) }

  findByServiceRoomId(page: Page, serviceRoomId: number) {
    let pageP = '';
    if (page)
      pageP = "&" + page.getPageQueryParams();
    let url = `${FIND_BY_SERVICE_ROOM_ID_URL}&serviceRoomId=${serviceRoomId}${pageP}&sort=read,asc&sort=created,desc&size=10`;
    return this.get<GetList<OperatorInactivityEventList>>(url);
  }

}
