import { Hateoas } from './hateos';
import { Area } from './area';
import { HrefLink } from '../interface/generic-links';
import { Company } from './company';
import { ServiceRoom } from './service-room';
import { Position } from './position';


export class PickUpPoint extends Hateoas<PickUpPointLinks>{
    name: string;
    code: string;
    position:Position;
    description: string;
    area: string | Area;
    company: any | Company;
    serviceRoom: ServiceRoom;
    companyCodes: string[];
    qrcode: string;
}

export interface PickUpPointLinks {
    area: HrefLink;
    company: HrefLink;
    self: HrefLink;
    position: HrefLink;
}

export interface PickUpPointGetList {
    pickUpPoints:PickUpPoint[];
}
