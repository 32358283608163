import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetList } from '../interface/generic-links';
import { Page } from '../interface/page';
import { ConventionProposal, ConventionProposalList } from '../model/convention-proposal';
import { HateoasService } from './hateoas.service';

const CP_BASE_URL = `${environment.endpointUri}/conventionProposals`;
const FIND_BY_CREATED_BETWEEN_BASE_URL = `${CP_BASE_URL}/search/findByCreatedBetween?projection=ConventionProposalData&sort=created,desc`;
const EXPORT_EXCEL_URL = `${environment.endpointUri}/convention-proposal/export`;

@Injectable({
  providedIn: 'root'
})

export class ConventionProposalService extends HateoasService {


  constructor(http: HttpClient) { super(http) }

  getPaged(page: Page) {
    let pageP = page.getPageQueryParams();
    let url = `${CP_BASE_URL}?projection=ConventionProposalData&${pageP}`;
    return this.get<GetList<ConventionProposalList>>(url);
  }


  findByDateBetween(dataInizio: string, dataFine: string, page: Page) {
    let pageP = page.getPageQueryParams();
    let url = `${FIND_BY_CREATED_BETWEEN_BASE_URL}&${pageP}&dataInizio=${dataInizio}&dataFine=${dataFine}`;
    return this.get<GetList<ConventionProposalList>>(url);
  }

  create(conventionProposal: ConventionProposal) {
    return this.createObject(CP_BASE_URL, conventionProposal);
  }

  update(conventionProposal: ConventionProposal) {
    let url = conventionProposal._links ? conventionProposal._links.self.href : `${CP_BASE_URL}/${conventionProposal.id}`
    return this.updateObject(url, conventionProposal);
  }

  exportExcel(dataInizio: string, dataFine: string) {
    return this.http.get(`${EXPORT_EXCEL_URL}?dataInizio=${dataInizio}&dataFine=${dataFine}`, { responseType: "blob" })
      .pipe(
        map(response => {
          if (response instanceof Response) {
            return response.blob();
          }
          return response;
        }
      ));
  }
}
