import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {map, catchError, finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShUserNotifyService } from './sh-user-notify.service';
import { InterceptorService, PrincipalService } from 'dst-security';
import { HandleErrorService } from './handle-error.service';
import { Constants } from '../constantsEndpoint';
import { OperatorService } from './operator.service';
import {UtilityService} from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class CustomInterceptorService extends InterceptorService {

  myRouter: Router

  constructor(
    router: Router,
    principalService: PrincipalService,
    private operatorService:OperatorService,
    handleErrorService: HandleErrorService,
    private shUserNotifyService: ShUserNotifyService,
    private utilityService: UtilityService
  ) {
    super(router, principalService, handleErrorService);
    // handleErrorService.handleError(new HttpErrorResponse({"error":"ao ao ao "}));
    this.myRouter = router;
  }

  public preCallRestApi() {
    this.utilityService.startLoaderBar();
  }

  public postCallRestApi() {
    this.utilityService.stopLoaderBar();
    let company = localStorage.getItem(Constants.ACTIVE_COMPANY_KEY);
    let token = localStorage.getItem(Constants.X_AUTH_TOKEN);
    let op= this.operatorService.getActiveOperator();
    if(op==null) {
      this.operatorService.setActiveOperator();
    }
    if (company == null && token != null && op!=null) {
      
      this.myRouter.navigate([Constants.SET_ACTIVE_COMPANY_URL]);
    }
    
  }
}
