import { HrefLink } from 'dst-security/lib/interface/generic-links';
import { Hateoas } from './hateos';

export class Company  extends Hateoas<CompanyLinks> {
    name: string;
    code: string;
}

export interface CompanyLinks {
    self: HrefLink;
}

export interface CompanyGetList {
    companies:Company[];
}