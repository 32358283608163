export class Page {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;

    constructor (values: Object = {}) {
        Object.assign(this,values);
    }

    getPageQueryParams() {
        return `size=${this.size}&page=${this.number}`;
    }

    nextPage() {
        if(this.number<this.totalPages)
            this.number++;
    }

    hasMoreResults() {
        return this.number<this.totalPages -1;
    }
}

export class PageAndSort extends Page{
    sortBy?: string;
    sortDirection?: 'asc'| 'desc';
}
