import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { filter, first, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';


const WEBSOCKET_ENDPOINT: string = `${environment.endpointUri}/avis-websocket`;

@Injectable({
  providedIn: 'root'
})

export class WebsocketService {

  private client: Stomp.Client;
  private state: BehaviorSubject<SocketClientState>;

  constructor() {
    console.debug("Initializing websocket client...");
    this.client = Stomp.over(new SockJS(WEBSOCKET_ENDPOINT));
    this.state = new BehaviorSubject<SocketClientState>(SocketClientState.ATTEMPTING);
    this.client.connect({}, () => {
      this.state.next(SocketClientState.CONNECTED);
      console.debug("Websocket client connected")
    });
  }

  ngOnDestroy() {
    this.connect().pipe(first()).subscribe(client => client.disconnect(null));
  }

  private connect(): Observable<Stomp.Client> {
    return new Observable<Stomp.Client>(observer => {
      this.state.pipe(filter(state => state === SocketClientState.CONNECTED)).subscribe(() => {
        observer.next(this.client);
      });
    });
  };


  onMessage<T>(topic: string): Observable<WebSocketResponse<T>> {
    return this.connect().pipe(first(), switchMap(client => {
      return new Observable<any>(observer => {
        const subscription: Stomp.Subscription = client.subscribe(topic, message => {
          let response = new WebSocketResponse<T>();
          response.body = JSON.parse(message.body);
          response.subscriptionId = subscription.id;
          observer.next(response);
        });
        return () => client.unsubscribe(subscription.id);
      });
    }));
  }

  unsubscribe(subscribiptionId: string) {
    this.client.unsubscribe(subscribiptionId);
  }
}

export enum SocketClientState {
  ATTEMPTING, CONNECTED
}

export class WebSocketResponse<T> {
  body: T;
  subscriptionId: string;
}