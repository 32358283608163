import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {LanguageService} from '../../../services/language.service';

@Component({
    selector: 'app-language-toolbar',
    templateUrl: './language-toolbar.component.html',
    styleUrls: ['./language-toolbar.component.scss'],
})
export class LanguageToolbarComponent implements OnInit, OnDestroy {

    languages = this.languageService.getAllLanguage();
    selectedLanguage: { language: string };
    langueControl: FormControl = new FormControl();
    changeSub: Subscription = new Subscription();

    constructor(private languageService: LanguageService) {
    }

    ngOnInit() {
        this.changeSub = this.langueControl.valueChanges.subscribe( res => {
            this.chooseLanguage(res);
        });

        this.languageService.getAppLanguage().subscribe(currentLanguage => {
            this.langueControl.setValue(currentLanguage.language, {emitEvent: false});
        });
    }

    chooseLanguage(language: string) {
        this.languageService.setAppLanguage(language);
    }

    ngOnDestroy() {
        this.changeSub.unsubscribe();
    }
}
