import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageEnum} from '../enums/language.enum';
import {BehaviorSubject, Observable} from 'rxjs';
import {DateAdapter} from '@angular/material/core';
import {environment} from '../../environments/environment';
import {HateoasService} from './hateoas.service';
import {LanguageGetList} from "../model/language";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    public defaultLanguage: string = environment.DEFAULT_LANGUAGE;
    private languageApp: BehaviorSubject<{ language: string }> = new BehaviorSubject({language: this.defaultLanguage});
    private enabledLanguage = environment.ACTIVE_LANGUAGE;
    private languageListBe: LanguageGetList[];

    constructor(
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<any>,
        private hateosService: HateoasService
        ) {
        this.initLanguageApp();
    }

    private initLanguageApp() {
        this.translateService.addLangs(this.enabledLanguage);
        this.translateService.setDefaultLang(this.defaultLanguage);
        const storageLanguage: string = localStorage.getItem('appLanguage');
        const browserLanguage: string = this.translateService.getBrowserCultureLang();

        let appLanguage;
        if (storageLanguage && Object.values(this.enabledLanguage).some(arrayLang => arrayLang === storageLanguage)) {
            appLanguage = storageLanguage;
        } else {
            if (browserLanguage && Object.values(this.enabledLanguage).some(arrayLang => arrayLang === browserLanguage)) {
                appLanguage = browserLanguage;
            } else {
                appLanguage = this.defaultLanguage;
            }
        }
        this.setAppLanguage(appLanguage);
    }

    public setAppLanguage(lang: string) {
        localStorage.removeItem('appLanguage');
        this.languageApp.next({language: lang});
        localStorage.setItem('appLanguage', lang);
        this.translateService.use(lang);
        this.dateAdapter.setLocale(lang);
    }

    public getAllLanguage() {
        return this.enabledLanguage;
    }

    public getAppLanguage(): BehaviorSubject<{ language: string }> {
        return this.languageApp;
    }
    
    public getLangaugesFromBe(): Observable<LanguageGetList> {
        const url = `${environment.endpointUri}/languages`;
        return this.hateosService.get<LanguageGetList>(url);
    }
}
