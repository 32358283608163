import { Component, OnInit } from '@angular/core';
import { PrincipalService } from 'dst-security';
import { OperatorService } from 'src/app/services/operator.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { PopoverController } from '@ionic/angular';
import { InactiveOperatorPopoverComponent } from '../inactive-operator-popover/inactive-operator-popover.component';
import { EventsService } from 'src/app/services/events.service';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-inactive-operator-badge',
  templateUrl: './inactive-operator-badge.component.html',
  styleUrls: ['./inactive-operator-badge.component.scss'],
})

export class InactiveOperatorBadgeComponent implements OnInit {

  topicUrl: string = "/topic/inactive-operators/";
  subscriptionId: string;
  serviceRoomId: number;
  unread: number = 0; // numero di notifiche non lette
  loginEventSubscription: Subscription;
  logoutEventSubscription: Subscription;
  readEventSubscription: Subscription;

  constructor(public principalService: PrincipalService, private operatorService: OperatorService, private websocketService: WebsocketService, public popoverController: PopoverController, public eventsService: EventsService) {
    // prova a fare una subscription con i dati in local storage
    this.subscribe();

    // al login avvia subscription al topic tramite websocket
    // jacopo: l'ideale sarebbe chiamare subito l'evento dello scheduler legato al websocket, nella casistica peggiore ci sono 59 secondi di attesa prima di vedere il numero aggiornato. come fare?
    this.loginEventSubscription = eventsService.subscribe(EventsService.LOGIN_EVENT, () => {
      this.subscribe();
    })

    // al logout annulla subscription al topic tramite websocket
    this.logoutEventSubscription = eventsService.subscribe(EventsService.LOGOUT_EVENT, () => {
      this.unsubscribe();
    })

    // quando dal popover una notifica viene letta, diminuisce il conteggio per mantenere in sync
    // jacopo: l'ideale sarebbe richiamare direttamente l'evento dello scheduler legato al websocket, come fare?
    this.readEventSubscription = eventsService.subscribe(EventsService.READ_INACTIVITY_EVENT, () => {
      if (this.unread > 0)
        this.unread = this.unread - 1;
    })
  }

  ngOnInit(

  ) { }

  ngOnDestroy() {
    this.unsubscribe();
    this.loginEventSubscription.unsubscribe();
    this.logoutEventSubscription.unsubscribe();
    this.readEventSubscription.unsubscribe();
  }

  subscribe() {
    if (this.subscriptionId != null) {
      return
    }

    if (localStorage.getItem("ACTIVE_OPERATOR_KEY") == null) {
      return
    }

    if (!this.principalService.isUserInRoles(['smart-checkout.service_manager', 'smart-checkout.supervisor']) == true) {
      return
    }

    let activeOperator = this.operatorService.getActiveOperator();
    this.serviceRoomId = activeOperator.serviceRoomId;
    this.topicUrl = this.topicUrl + this.serviceRoomId;

    this.websocketService
      .onMessage<NumberDTO>(this.topicUrl).subscribe((res) => {
        this.unread = res.body.number;
        this.subscriptionId = res.subscriptionId;
      })
  }

  unsubscribe() {
    this.websocketService.unsubscribe(this.subscriptionId);
    this.subscriptionId = null;
    this.unread = 0;
  }


  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: InactiveOperatorPopoverComponent,
      event: ev,
      componentProps: {
        "serviceRoomId": this.serviceRoomId
      }
    });
    return await popover.present();

  }
}


export class NumberDTO {
  number: number
}
