import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserNotifyService } from 'dst-security';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class ShUserNotifyService  extends UserNotifyService  {

  static snackBar: MatSnackBar;
  notify = UtilityService.showMessage;

  constructor(public snackBar: MatSnackBar, private injector: Injector) {
    super();
  }

  notifySuccess(message) {
    let translateService = this.injector.get(TranslateService);
    message = message ? message : translateService.instant("enum.SuccessCode.SUCCESS");
    this.notify(message, 'Success');
  }

  notifyInfo(message) {
    let translateService = this.injector.get(TranslateService);
    message = message ? message : translateService.instant("enum.SuccessCode.INFO");
    this.notify(message, 'Info');
  }

  notifyWarning(message) {
    let translateService = this.injector.get(TranslateService);
    message = message ? message : translateService.instant("enum.ErrorCode.WARNING");
    this.notify(message, 'Warn');
  }

  notifyError(message?) {
    let translateService = this.injector.get(TranslateService);
    message = message ? message : translateService.instant("enum.ErrorCode.ERROR");
    //message = message ? message : "Si è verificato un errore.";
    this.notify(message, 'Error');
  }
}
