import { Schedule } from './schedule';
import { Hateoas } from './hateos';
import { Operator } from './operator';

export class Activity  extends Hateoas<ActivitiesGetList>{
    created:Date;
    modified:Date;
    principalCreatorId:string;
    principalModifierId:string;
    note:string;
    schedule: string;
    duration:number | Date;
    operator: string;
}

export interface ActivityLinks{}

export interface ActivitiesGetList {
    operatorActivities: Activity[];
}