import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-priority-dot',
  templateUrl: './priority-dot.component.html',
  styleUrls: ['./priority-dot.component.scss'],
})
export class PriorityDotComponent implements OnInit {
  @Input() priority;

  constructor() { }

  ngOnInit() {}

}
