import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { HateoasService } from 'src/app/services/hateoas.service';
import { PickUpPoint, PickUpPointGetList } from 'src/app/model/pick-up-point';
import { GetList } from 'src/app/interface/generic-links';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/model/company';
import { OperatorService } from 'src/app/services/operator.service';
import { Operator } from 'src/app/model/operator';
import { Subscription } from 'rxjs';

const PUP_BASE_URL = `${environment.endpointUri}/pickUpPoints`;
@Component({
  selector: 'app-pick-up-point-select',
  templateUrl: './pick-up-point-select.component.html',
  styleUrls: ['./pick-up-point-select.component.scss'],
})
export class PickUpPointSelectComponent implements OnInit, OnDestroy {

  @Input() roundSelect: boolean = true;
  @Input() redTheme: boolean = false;
  @Input() callPageStyle: boolean = false;
  @Input() disabled: boolean = false;
  pickUpPoints: Array<PickUpPoint> = new Array<PickUpPoint>();

  @Output() selected = new EventEmitter<PickUpPoint>();

  @Input() value = new PickUpPoint();

  @Input()selectedPointId : number;

  @Input() noneOption=false;
  @Input() floatLabel: boolean = false;


  company: Company;
  operator: Operator;
  companySub: Subscription = new Subscription();

  constructor(private service: HateoasService
    , private companyService: CompanyService
    , private operatorService: OperatorService) { }

  ngOnInit() {
    this.companySub = this.companyService.selectedCompanyConfirmed.subscribe( res => {
      this.getPups();
    });
    this.getPups();
  }

  getPups() {
    this.operator = this.operatorService.getActiveOperator();
    this.company = this.companyService.getActiveCompany();
    let url = PUP_BASE_URL;
    this.pickUpPoints = [];
    // if(this.operator.area && this.operator.area.id)
    // url = `${url}/search/findCompanyId?areaId=${this.operator.area.id}&companyId=${this.company.id}`
    url = `${url}/search/findByCompanyIdOrderByNameAsc?projection=PUPData&companyId=${this.company.id}`;
    this.service.get<GetList<PickUpPointGetList>>(url).subscribe(result => {
      this.pickUpPoints = result._embedded.pickUpPoints;
    });
  }

  ngOnDestroy(){
    this.pickUpPoints = new Array<PickUpPoint>();
    this.companySub.unsubscribe();
  }

  onChange(value) {
    this.selected.emit(value);
  }

  compareWithFn = (o1, o2) => {
    let pup1 = new PickUpPoint(o1);
    let pup2 = new PickUpPoint(o2);
    return o1 && o2 ? pup1.getIdBySelf() === pup2.getIdBySelf() : o1 === o2;
  };

  compareWith = this.compareWithFn;
}
