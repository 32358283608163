import { environment } from 'src/environments/environment';

export const ConstantsEndpoint = {
    LOGIN_SUCCESS_URL: '/set-active-company',
    SECURITY_END_POINT: environment.endpointUri,
    DOCUMENT_END_POINT: environment.endpointUri
};

export const Constants = {
    ACTIVE_COMPANY_KEY:"ACTIVE_COMPANY_KEY",
    ACTIVE_OPERATOR:"ACTIVE_OPERATOR_KEY",
    LANDING_URL: "/calls-management",
    SET_ACTIVE_COMPANY_URL:"/set-active-company",
    X_AUTH_TOKEN: "x-auth-token"
}