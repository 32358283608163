import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HateoasService } from 'src/app/services/hateoas.service';
import { ServiceRoom, ServiceRoomGetList } from 'src/app/model/service-room';
import { environment } from 'src/environments/environment';
import { GetList } from 'src/app/interface/generic-links';
import { ServiceRoomService } from 'src/app/services/service-room.service';

const LIST_SERVICE_ROOMS = `${environment.endpointUri}/serviceRooms`

@Component({
  selector: 'app-service-room-select',
  templateUrl: './service-room-select.component.html',
  styleUrls: ['./service-room-select.component.scss'],
})
export class ServiceRoomSelectComponent implements OnInit {
  serviceRooms: Array<ServiceRoom> = new Array();

  @Input() roundSelect: boolean = true;
  @Input() myServiceRoom: ServiceRoom;
  @Input() redTheme: boolean = false;
  @Input() floatLabel: boolean = false;
  @Input() emptyOption: boolean = false;
  @Output() selected = new EventEmitter<ServiceRoom>();

  constructor(private service: HateoasService) { }

  ngOnInit() {
    this.loadServiceRooms();
  }

  ionViewDidEnter() {
    // this.loadServiceRooms(); necessario?
  }

  loadServiceRooms() {
    this.service.get<GetList<ServiceRoomGetList>>(LIST_SERVICE_ROOMS).subscribe(
      res => {
        this.serviceRooms = res._embedded.serviceRooms;
      }
    )
  }

  onChangeServiceRoom(value) {
    this.selected.emit(value);
    console.debug(value);
  }

  compareWithFn = (o1, o2) => {
    let a1 = new ServiceRoom(o1);
    let a2 = new ServiceRoom(o2);
    return o1 && o2 ? a1.id === a2.id : o1 === o2;
  };

  compareWith = this.compareWithFn;


}
