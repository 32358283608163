import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modale-remove-note',
  templateUrl: './modale-remove-note.component.html',
  styleUrls: ['./modale-remove-note.component.scss'],
})
export class ModaleRemoveNoteComponent implements OnInit {

  message: string = this.translateService.instant("shared.modale-remove-note.Sei sicuro di voler eliminare la nota selezionata?");

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private translateService: TranslateService
  ) { 
    if(this.navParams.get('message') != undefined ){
      this.message = this.navParams.get('message');
    }
  }

  ngOnInit() {}

  confirm() {
    this.modalController.dismiss({ 'confirm': true });
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
