import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HateoasService } from 'src/app/services/hateoas.service';
import { ShUserNotifyService } from 'src/app/services/sh-user-notify.service';
import { PrincipalService } from 'dst-security';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cambia-pass',
  templateUrl: './cambia-pass.component.html',
  styleUrls: ['./cambia-pass.component.scss'],
})
export class CambiaPassComponent implements OnInit {

  passwordForm = new FormGroup({});

  //almeno 8 caratteri, un numero, una maiuscola e un carattere speciale 
  // () _ ! @ # $ % & ^ * -
  passRegexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[)(_!@#\$%\^&\*\-])(?=.{8,})");

  constructor(private modalController: ModalController, private principalService: PrincipalService,
    private hateosService: HateoasService, private shUserNotifyService: ShUserNotifyService, 
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.passwordForm.addControl('password', new FormControl('', [Validators.required, Validators.pattern(this.passRegexp)]));
    this.passwordForm.addControl('confermaPass', new FormControl('', [Validators.required, Validators.pattern(this.passRegexp)]));
  }

  dismiss() {
    this.modalController.dismiss();
  }

  conferma() {
    if (this.passwordForm.get('password').value == this.passwordForm.get('confermaPass').value) {
      this.principalService.changePassword(this.passwordForm.get('confermaPass').value).subscribe(res => {
        this.shUserNotifyService.notifySuccess(this.translateService.instant("enum.SuccessCode.PW_CHANGE"));
        this.modalController.dismiss(res);
      });

    } else {
      this.shUserNotifyService.notifyWarning(this.translateService.instant("enum.ErrorCode.PW_NO_MATCH"));
    }
  }

}