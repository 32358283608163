import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HateoasService } from './hateoas.service';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends HateoasService {

  constructor(http: HttpClient) {
    super(http);
   }
}
