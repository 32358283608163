import { Injectable } from '@angular/core';
import { HateoasService } from './hateoas.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GetList } from '../interface/generic-links';
import { SchedulesGetList, Schedule } from '../model/schedule';
import { StatoSmartCheckOut } from '../enums/stato-smart-check-out.enum';
import { Subject } from 'rxjs/internal/Subject';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Page } from '../interface/page';
import { ServiceRoom, ServiceRoomGetList } from '../model/service-room';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { isArray } from 'util';
import { UtilityService } from './utility.service';
import { PickUpPointGetList, PickUpPoint } from '../model/pick-up-point';
import { LoadingStatus } from '../model/loading-status';
import { Constants } from '../constantsEndpoint';
import {map} from 'rxjs/operators';
import {saveAs} from 'file-saver';

const BASE_URL = `${environment.endpointUri}/schedules`;
const clientsByStatus = `${BASE_URL}/search/findByStatus?projection=ScheduleData&status=`;
const CLIENTS_BY_STATUS_SORT_DATE_START_VAL = `${BASE_URL}/search/findByStatusOrderByChangeStatus?projection=ScheduleData&status=`;
const assignSchedule = `${BASE_URL}/`;
const myScheduleByStatus = `${BASE_URL}/search/findMyScheduleByStatus?projection=ScheduleData&status=`;
const assignDeskUrl = `${environment.endpointUri}/scheduler/assignToDesk`;
const GET_SCHEDULES_IN_ATTESA_URL: string = `${environment.endpointUri}/schedules/search/checkoutByStatusAndDate?projection=ScheduleData&sort=priority,desc`;
const serviceroom_url = `${environment.endpointUri}/serviceRooms/`;
const reservation_url = `${environment.endpointUri}/reservation/`;
const scheduler_url = `${environment.endpointUri}/scheduler/`;
const pup_url = `${environment.endpointUri}/pickUpPoints`;
const GET_SCHEDULES_PECARICATO_URL: string = `${environment.endpointUri}/schedules/search/findbyStatusOrdeByPUC?projection=ScheduleData&free=true`


@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends HateoasService {

  notAssignedSchedulesSource = new Subject<number>();
  notAssignedSchedulesConfirmed = this.notAssignedSchedulesSource.asObservable();

  abgMagInAttesaSource = new Subject<any>();
  abgMagInAttesaConfirmed = this.abgMagInAttesaSource.asObservable();

  // inAttesaTimer: Subscription;
  inAttesaBrandTimer: Subscription;
  serviceRoomTimer: Subscription;
  desksTimer: Subscription;
  dashboardTimer: Subscription;
  dashboardTimer2: Subscription;
  operatorViewAnyServiceRoom: boolean = environment.operatorViewAnyServiceRoom;

  constructor(http: HttpClient, private datePipe: DatePipe) { super(http); }

  checkoutByStatusAndDate(status: string, pickDateStart: Date, pickDateEnd: Date, serviceRoomId: number, filter: string, page: Page, pickUpPointId?: number, assigned: string = "") {
    let order = "&sort=dateStatusChange,asc";
    if (status != StatoSmartCheckOut.IN_ATTESA)
      order = "&sort=modified,desc";
    let pageP = '';
    let start = moment(pickDateStart.toISOString());
    if (page)
      pageP = "&" + page.getPageQueryParams();
    let url = `${GET_SCHEDULES_IN_ATTESA_URL}${order}&pickDate=${pickDateStart.toISOString().substring(0, 10)}&pickDateEnd=${this.datePipe.transform(pickDateEnd, 'yyyy-MM-dd')}&statuses=${status}&serviceRoomId=${serviceRoomId ? serviceRoomId : ''}&assigned=${assigned}&filter=${filter.trim()}${pageP}`;
    url = pickUpPointId ? `${url}&pickUpPointId=${pickUpPointId}` : url;

    return this.get<GetList<SchedulesGetList>>(url);
  }


  getScheduleHold(companyId?: number, serviceRoomId?: number) {
    return this.getScheduleByStatus(StatoSmartCheckOut.ON_HOLD, companyId, true, serviceRoomId)
  }

  getSchedulePrecaricato(page: Page, companyId?: number, serviceRoomId?: number, date?: Date, pickUpPointId?: number) {
    //il sort è fissato sul server e non può essere apssato a parametro
    date = date ? date : new Date();
    let pageP = '';
    if (page)
      pageP = "&" + page.getPageQueryParams();
    let url = `${GET_SCHEDULES_PECARICATO_URL}${pageP}&status=${StatoSmartCheckOut.PRECARICATO}&pickDate=${this.datePipe.transform(date, 'yyyy-MM-dd')}`;
    url = serviceRoomId ? `${url}&serviceRoomId=${serviceRoomId}` : url;
    url = companyId ? `${url}&companyId=${companyId}` : url;
    url = pickUpPointId ? `${url}&pickUpPointId=${pickUpPointId}` : url;

    return this.get<GetList<SchedulesGetList>>(url);

    // return this.getScheduleByStatus(StatoSmartChekOut.PRECARICATO, companyId, true, serviceRoomId, 'sort=priority,desc&sort=created,asc&size=60')

  }

  getScheduleIncome(companyId?: number) {
    return this.getScheduleByStatus(StatoSmartCheckOut.ON_HOLD, companyId)
  }

  getScheduleByStatus(status: StatoSmartCheckOut | StatoSmartCheckOut[], companyId?: number, free?: boolean, serviceRoomId?: number, sort?: string) {
    let myStatus: string = null;
    if (isArray(status)) {
      status.forEach(st => {
        if (myStatus == null)
          myStatus = `${st}`;
        else
          myStatus = `${myStatus},${st}`;
      })
    } else {
      myStatus = `${status}`
    }

    let url = `${clientsByStatus}${myStatus}`;
    if (companyId)
      url = `${url}&companyId=${companyId}`;
    if (free != null)
      url = `${url}&free=${free}`;
    if (serviceRoomId && !this.operatorViewAnyServiceRoom) {
      url = `${url}&serviceRoomId=${serviceRoomId}`;
    }
    if (sort)
      url = `${url}&${sort}`;
    return this.get<GetList<SchedulesGetList>>(url);
  }

  findMyScheduleByStatus(status: StatoSmartCheckOut | StatoSmartCheckOut[], deskId: number, sort?: string) {
    let myStatus: string = null;
    if (isArray(status)) {
      status.forEach(st => {
        if (myStatus == null)
          myStatus = `${st}`;
        else
          myStatus = `${myStatus},${st}`;
      })
    } else {
      myStatus = `${status}`
    }

    let url = `${myScheduleByStatus}${myStatus}`;
    // if (companyId)
    //   url = `${url}&companyId=${companyId}`;
    if (deskId != null)
      url = `${url}&deskId=${deskId}`;
    // if (serviceRoomId) {
    //   url = `${url}&serviceRoomId=${serviceRoomId}`
    // }
    if (sort)
      url = `${url}&${sort}`;
    else
      url = `${url}&sort=priority,desc&sort=created,asc`
    return this.get<GetList<SchedulesGetList>>(url);
  }

  setAssignedOnHold(scheduleHref) {
    let url = `${assignSchedule}${scheduleHref}`;
    return this.patchObject(url, { assigned: null });
  }

  getClientiInAttesa(status: StatoSmartCheckOut, companyId?: number, free?: boolean, serviceRoomId?: number) {
    let url = `${CLIENTS_BY_STATUS_SORT_DATE_START_VAL}${status}`;
    if (companyId)
      url = `${url}&companyId=${companyId}`;
    if (free)
      url = `${url}&free=${free}`;
    if (serviceRoomId && !this.operatorViewAnyServiceRoom) {
      url = `${url}&serviceRoomId=${serviceRoomId}`
    }
    // url = `${url}&sort=priority,desc&sort=created,asc&size=60`;
    return this.get<GetList<SchedulesGetList>>(url);
  }

  assignSchedule(deskHref: string, scheduleId) {
    let deskId = UtilityService.getIdBySelfHref(deskHref);
    return this.assignToDesk(null, deskId, scheduleId);
    // let url = `${assignSchedule}${scheduleHref}`;
    // return this.patchObject<Schedule>(url, { assigned: deskHref });
  }

  assignToDesk(agendaId, deskId, scheduleId) {

    // projection=Scheduledata 
    let url = `${assignDeskUrl}?projection=Scheduledata&scheduleId=${scheduleId}&deskId=${deskId}`;
    if (agendaId != null) {
      url = `${url}&agendaId=${agendaId}`
    }
    return this.patchObject<Schedule>(url, {});
  }

  getServiceRooms() {
    let url = `${serviceroom_url}`;
    return this.get<GetList<ServiceRoomGetList>>(url);
  }

  getServiceRoom(serviceRoomId: number) {
    let url = `${serviceroom_url}${serviceRoomId}`;
    return this.get<ServiceRoom>(url);
  }

  countSchedule(status: StatoSmartCheckOut, serviceRoomId?: number, brandId?: number, pickDate?: Date): Observable<number> {
    let url = `${BASE_URL}/search/countForDayAndStatusAndBrand?status=${status}`;
    if (serviceRoomId)
      url = `${url}&serviceRoomId=${serviceRoomId}`;
    if (brandId)
      url = `${url}&brandId=${brandId}`;
    if (pickDate)
      url = `${url}&pickDate=${pickDate}`;
    return this.get<number>(url);
  }

  // priorita(IdSchedule: number, priority: number){
  //   let url = `${assignSchedule}${IdSchedule}`;
  //   return this.patchObject<Schedule>(url,priority)
  // }
  // getCalendar(year: number, month: number){

  //   let url = `${environment.endpointUri}/scheduler/month?year=${year}&month=${month}`;

  //   return this.get(url);

  // }

  uploadStatus() {
    let url = `${reservation_url}PrePrintLoadingStatus`;
    return this.get<LoadingStatus>(url);
  }

  uploadPreprintFile(file) {
    let url = `${reservation_url}uploadFile?file`;
    return this.createObject(url, file);
  }

  getListaPup() {
    let url = `${pup_url}?projection=PUPData`;
    return this.get<GetList<PickUpPointGetList>>(url);
  }

  findByNameLikeIgnoreCase(name: string, page: number, size: number, sortField?: string) {
    let sort = 'sort=name,asc'
    let url = `${pup_url}/search/findByNameStartsWithIgnoreCase?projection=PUPData&${sort}&name=${name ? name : ''}&page=${page}&size=${size}`;
    return this.get<GetList<PickUpPointGetList>>(url);
  }

  resubmitSchedule(schedule) {
    let url = `${reservation_url}uploadXlsxRow`;
    return this.createObject(url, schedule);
  }

  creaAppuntamentoPreprint(schedule) {
    let url = `${scheduler_url}liveSchedule`;
    return this.createObject(url, schedule);
  }

  stopTimerinAttesaBrandTimer() {
    if (this.inAttesaBrandTimer) {
      this.inAttesaBrandTimer.unsubscribe();
      this.inAttesaBrandTimer = undefined;
    }
  }

  stopAllTimer() {
    if (this.serviceRoomTimer) {
      this.serviceRoomTimer.unsubscribe();
      this.serviceRoomTimer = undefined;
    }
    if (this.desksTimer) {
      this.desksTimer.unsubscribe();
      this.desksTimer = undefined;
    }
    if (this.dashboardTimer) {
      this.dashboardTimer.unsubscribe();
      this.dashboardTimer = undefined;
    }
    if (this.dashboardTimer2) {
      this.dashboardTimer2.unsubscribe();
      this.dashboardTimer2 = undefined;
    }
  }



  deletePreprint(ids: string) {
    let url = `${scheduler_url}deleteSchedulesByIds?schedulesId=${ids}`;
    return this.deleteObject(url);
  }

  exportExcelCalendar(dataFine: string, companyId: number) {
    // api/scheduler/export/prenotation?date=19/05/2021
    return this.http.get(`${scheduler_url}export/prenotation?date=${dataFine}&companyId=${companyId}`, {responseType: 'blob'})
        .pipe(
            map(response => {
                  if (response instanceof Response) {
                    return response.blob();
                  }
                  return response;
                }
            ));
  }

  countScheduleCalendar(dataInizio: string, dataFine: string, companyId: number) {
    const URL = `${scheduler_url}calendar/count?datainizio=${dataInizio}&datafine=${dataFine}&companyId=${companyId}`;
    return this.get<any>(URL);
  }

 getExcelSchedulerCanceled({serviceRoom = null, pickUpPoint = null}, dateStart, dateEnd) {
    dateStart = this.datePipe.transform(dateStart, 'yyyy-MM-dd');
    dateEnd = this.datePipe.transform(dateEnd, 'yyyy-MM-dd');

    let url = `${environment.endpointUri}/scheduler/export/canceled?dateStart=${dateStart}&dateEnd=${dateEnd}&stato=ANNULLATO`;
    url = (pickUpPoint) ? `${url}&pup=${pickUpPoint}` : url;
    url = (serviceRoom) ? `${url}&serviceRoom=${serviceRoom}` : url;
    
    console.log('url', url);
    return this.http.get(url, {responseType: 'blob'})
        .pipe(
            map(response => (response instanceof Response) ? response.blob() : response),
        )
  }

  getExcelSchedulerPerformangeAgent(dateStart, dateEnd) {
    dateStart = this.datePipe.transform(dateStart, 'yyyy-MM-dd');
    dateEnd = this.datePipe.transform(dateEnd, 'yyyy-MM-dd');

    let url = `${environment.endpointUri}/scheduler/export/schedules?dateStart=${dateStart}&dateEnd=${dateEnd}`;
    console.log('url', url);
    return this.http.get(url, {responseType: 'blob'}).pipe(map(response => (response instanceof Response) ? response.blob() : response))
  }

  countByStatus(companyId) {
  // api/schedules/search/countByStatus?status=IN_ATTESA&companyId=2&free=true
    const URL = `${environment.endpointUri}/schedules/search/countByStatus?status=IN_ATTESA&companyId=${companyId}&free=true`;
    return this.get<any>(URL);
  }
}
