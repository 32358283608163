import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {PrincipalService} from 'dst-security';
import {Observable} from 'rxjs';
import {Constants} from 'src/app/constantsEndpoint';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    readonly ROUTE_ROLES = {
        '/calls-management': ['smart-checkout.smart_checkout'],
        '/insert-appointment': ['smart-checkout.inserimento_appuntamenti'],
        '/pup-detail': ['smart-checkout.inserimento_clienti_in_coda', 'smart-checkout.dati_smart_checkout_pdr', 'smart-checkout.service_manager'],
        '/calendar': ['smart-checkout.service_manager', 'security.admin', 'smart-checkout.supervisor'],
        '/service-room-dashboard': ['smart-checkout.service_manager', 'security.admin', 'smart-checkout.supervisor'],
        '/users': ['smart-checkout.service_manager', 'security.admin'],
        '/customers-waiting': ['smart-checkout.service_manager', 'security.admin', 'smart-checkout.supervisor'],
        '/preprint': ['smart-checkout.service_manager', 'security.admin'],
        '/preprint/preprint-list': ['smart-checkout.service_manager', 'security.admin'],
        '/pup-management': ['smart-checkout.service_manager', 'security.admin'],
        '/conventions': ['smart-checkout.service_manager', 'smart-checkout.supervisor'],
        '/signature': ['smart-checkout.service_manager', 'smart-checkout.inserimento_appuntamenti', 'smart-checkout.supervisor'],
        '/check-scheduled-call': ['smart-checkout.service_manager', 'smart-checkout.inserimento_appuntamenti', 'smart-checkout.supervisor'],
        '/tablet-management': ['smart-checkout.service_manager', 'security.admin', 'smart-checkout.supervisor'],
        '/tab-tools': ['smart-checkout.service_manager', 'security.admin', 'smart-checkout.supervisor'],
        '/configuration': ['security.report', 'smart-checkout.report'],
        '/tabs-metabase': ['security.report', 'smart-checkout.report'],
        '/report-list/link-report': ['security.report', 'smart-checkout.report'],
        '/report-uso-per-country': ['security.superadmin', 'smart-checkout.report'],
    };

    constructor(private router: Router, private principalService: PrincipalService) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (localStorage.getItem(Constants.X_AUTH_TOKEN) == undefined || localStorage.getItem('user') == undefined) {
            this.router.navigate(['security/login']);
            return false;
        } else if (state.url == '/calls-management') {
            // pagina atterraggio
            return true;
        } else if (this.ROUTE_ROLES[state.url].length > 0 && !this.principalService.isUserInRoles(this.ROUTE_ROLES[state.url])) {
            return false;
        } else {
            return true;
        }
    }

}
