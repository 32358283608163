import { Injectable } from '@angular/core';
import { HateoasService } from './hateoas.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constantsEndpoint';
import { Operator } from '../model/operator';
import { PrincipalService } from 'dst-security';
import { Principal } from 'dst-security/lib/models/principal';
import { ServiceRoom } from '../model/service-room';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'dst-security/lib/services/utils.service';
import { UtilityService } from './utility.service';
import { ServiceRoomService } from './service-room.service';
import { EventsService } from './events.service';
import { Subject } from 'rxjs';

const OPERATOR_BASE_URL=`${environment.endpointUri}/operators`;
const OPERATOR_BY_PRINCIPAL_ID_URL=`${OPERATOR_BASE_URL}/search/findOneByPrincipalId?projection=OperatorData&principalId=`
@Injectable({
  providedIn: 'root'
})
export class OperatorService extends HateoasService{

  constructor(http: HttpClient, private principalService: PrincipalService, private serviceRoomService: ServiceRoomService, private eventsService: EventsService) { super(http) }

  setActiveOperator() {
    let opString: string =localStorage.getItem(Constants.ACTIVE_OPERATOR);
    if(opString==null || opString.length<1) {
      this.getMyOperator().subscribe(
        result=>{
          localStorage.setItem(Constants.ACTIVE_OPERATOR,JSON.stringify(result));
          if (!this.serviceRoomService.getStoredServiceRoom()) {
            this.get(result._links.serviceRoom.href).subscribe(res => {
              this.serviceRoomService.setStoredServiceRoom(res as ServiceRoom);
            })
          }
          this.eventsService.publish(EventsService.LOGIN_EVENT);
        },
        error => {
          if(error.status=="404")
           localStorage.setItem(Constants.ACTIVE_OPERATOR,"{\"name\":\"OPERATOR NOT FOUND\"}");
        }
      );
    }
  }

  getMyOperator() {
    let princ: Principal =this.principalService.getLoggedPrincipal();
    if(princ == null)
      return new Subject<Operator>();
    let principalId: number= UtilityService.getIdBySelfHref(princ._links.self.href);
    return this.get<Operator>(OPERATOR_BY_PRINCIPAL_ID_URL+principalId);
  }

  getActiveOperator(): Operator {
    let opString: string =localStorage.getItem(Constants.ACTIVE_OPERATOR);
    if(opString && opString.length>0)
      return new Operator(JSON.parse(opString));
    return null;
  }

}
