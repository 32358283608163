import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import * as moment from 'moment';

@Component({
    selector: 'app-modal-from-to-date',
    templateUrl: './modal-from-to-date.component.html',
    styleUrls: ['./modal-from-to-date.component.scss'],
})
export class ModalFromToDateComponent implements OnInit {
    @Input() dateFrom: Date;
    @Input() dateTo: Date;

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
        //
    }

    dismiss() {
        this.modalController.dismiss();
    }

    okButton() {
        this.modalController.dismiss(
            {
                okButton: true,
                dateFrom: moment(this.dateFrom).toDate(),
                dateTo: moment(this.dateTo).toDate()
            }
        );
    }

    dateChanged($event: any) {

    }
}
