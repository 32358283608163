import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Principal } from 'dst-security/lib/models/principal';
import { Profile } from 'dst-security/lib/models/profile';
import { GetList } from 'src/app/interface/generic-links';
import { HateoasService } from 'src/app/services/hateoas.service';
import { OperatorService } from 'src/app/services/operator.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-select',
  templateUrl: './profile-select.component.html',
  styleUrls: ['./profile-select.component.scss'],
})
export class ProfileSelectComponent implements OnInit, OnChanges {

  profilesArray: Profile[] = []; 
  @Input() required: boolean = false;
  @Input() redTheme: boolean = false;
  @Input() value: Profile;
  user: Principal;
  @Output() selectedProfile: EventEmitter<Profile> = new EventEmitter<Profile>();

  constructor(private service: HateoasService, private operatorService: OperatorService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getProfiles();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.value && changes.value.currentValue) {
      this.setValueFromArray();
    }
  }

  getProfiles() {
    const url = `${environment.endpointUri}/profileImpls`;
    this.service.get<GetList<any>>(url).subscribe( results => {
      let userRole = this.getRoleNumber((this.user.profile as Profile).description);
      results._embedded.profileImpls.forEach(element => {
        if (this.getRoleNumber(element.description) > userRole) {
          this.profilesArray.push(element);
        }
      });
      this.setValueFromArray();
    });
  }

  setValueFromArray() {
    if (this.value && this.value._links) {
      let i = this.profilesArray.findIndex( x => x._links.self.href == this.value._links.self.href);
      if (i > -1) this.value = this.profilesArray[i];
    }
  }

  onChange(event: any) {
    this.selectedProfile.emit(event.target.value);
  }

  getRoleNumber(description): number {
    return Number(description.replace(new RegExp("(?= - ).*"), ""));
  }

}
