import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modale-elemento-pending',
  templateUrl: './modale-elemento-pending.component.html',
  styleUrls: ['./modale-elemento-pending.component.scss'],
})
export class ModaleElementoPendingComponent implements OnInit {

  message : string = '';
  constructor(private modalController: ModalController, private navParams : NavParams) {
    this.message = this.navParams.get('messaggio');
   }

  ngOnInit() {}

 
  dismiss() {
    this.modalController.dismiss();
  }

  conferma() {
    this.modalController.dismiss(true);
  }

}
