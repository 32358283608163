import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { Platform, ModalController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, RouterEvent } from '@angular/router';
import { ModaleElementoPendingComponent } from './feature/modal/modale-elemento-pending/modale-elemento-pending.component';
import { CambiaPassComponent } from './feature/modal/cambia-pass/cambia-pass.component';
import { OperatorService } from './services/operator.service';
import { PrincipalService } from 'dst-security';
import { Operator } from './model/operator';
import { DeskService } from './services/desk.service';
import {forkJoin, Observable, Subscription, timer} from 'rxjs';
import { CompanyService } from './services/company.service';
import { Desk } from './model/desk';
import { ScheduleService } from './services/schedule.service';
import { environment } from 'src/environments/environment';
import { AudioNotifyService } from './services/audio-notify.service';
import { Version, VersionList } from './model/mobile-app-version';
import { GetList } from './interface/generic-links';
import { WebsocketService } from './services/websocket.service';
import { OperatorInactivityService } from './services/operator-inactivity.service';
import { EventsService } from './services/events.service';
import { ConventionProposalService } from './services/convention-proposal.service';
import { ServiceRoom } from './model/service-room';
import { ServiceRoomService } from './services/service-room.service';
import {UtilityService} from './services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import {Company, CompanyGetList} from "./model/company";
import {HateoasService} from "./services/hateoas.service";
import { Constants } from './constantsEndpoint';
import { ShUserNotifyService } from './services/sh-user-notify.service';
import { AuthGuard } from './core/guards/auth.guard';

const GET_COUNT_SCHEDULES_URL = `${environment.endpointUri}/schedules/search/countFreeSchedules?idServiceRoom=`;
const checkUpdateUrl = `${environment.endpointUri}/mobileAppVersions/search/checkUpdate?size=1`
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  nomeUtente: string = "";
  operator: Operator;
  selectCompanySubscription: Subscription;
  authSubscribe: Subscription;
  companyLocalStorageValue: string;
  private deskObserver;
  myDesk: Desk;
  private serviceRoomObserver;
  myServiceRoom: ServiceRoom;
  selectedPath = '/';
  notAssignedSchedulesNumber = 0;
  activeOperator: Operator;
  notAssignedSchedulesSubscription: Subscription;
  abgmagSupscription: Subscription;
  abgInAttesaNumber: number = 0;
  magInAttesaNumber: number = 0;
  versioneApp: number;
  toast: any;
  enabledLoadergBar: boolean = false;
  private loaderSubscription: Subscription;
  private counterSubscription: Subscription;
  public companyList;
  private companyListObserv: Observable<Company[]>;
  public operatorViewAnyServiceRoom: boolean = environment.operatorViewAnyServiceRoom;
  menuExpanded: boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private modalCtrl: ModalController,
    private operatorService: OperatorService,
    public principalService: PrincipalService,
    private deskService: DeskService,
    private companyService: CompanyService,
    private scheduleService: ScheduleService,
    private audioNotifyService: AudioNotifyService,
    public toastController: ToastController,
    public websocketService: WebsocketService,
    public operatorInactivityService: OperatorInactivityService,
    public eventsService: EventsService,
    public conventionProposalService: ConventionProposalService,
    public serviceRoomService: ServiceRoomService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    private service: HateoasService,
    private sh: ShUserNotifyService,
    public authguard: AuthGuard
  ) {

    $("#header").css("display", "none");
    $("#splitPaneMenu").css("display", "none");

    this.initializeApp();
    this.router.events.subscribe((event: RouterEvent) => {
      if (event.url != undefined) {
        this.selectedPath = event.url;
      }
    });
    let self = this;
    this.deskObserver = {
      next(desk: Desk) {
        if (desk) {
          self.myDesk = desk;
        }
      }
    }
    this.serviceRoomObserver = {
      next(serviceRoom: ServiceRoom) {
        if (serviceRoom) {
          self.myServiceRoom = serviceRoom;
        }
      }
    }
    this.deskService.subscribeDeskChange(this.deskObserver);
    this.serviceRoomService.subscribeServiceRoomChange(this.serviceRoomObserver);

    this.selectCompanySubscription = this.companyService.selectedCompanyConfirmed.subscribe(
      company => {
        //setto una variabile del local storage  per poi recuperarla al refresh della pagina (f5)
        this.companyLocalStorageValue = company;
        localStorage.setItem("companyName", company);
      }
    );

    //recupero la variabile del local storage che ho settato sopra

    if (localStorage.getItem("companyName") != null) {
      this.companyLocalStorageValue = localStorage.getItem("companyName");
    }
    else if (localStorage.getItem("ACTIVE_COMPANY_KEY") != null) {
      this.companyLocalStorageValue = JSON.parse(localStorage.getItem("ACTIVE_COMPANY_KEY")).name;
    }


    
    this.authSubscribe = this.companyService.loginConfirmed.subscribe(
      operator => {
        this.myDesk = this.deskService.getStoredDesk();
        this.myServiceRoom = this.serviceRoomService.getStoredServiceRoom();
        this.nomeUtente = operator.name;
        this.operator = operator;
        this.activeOperator = this.operatorService.getActiveOperator();
        this.getCounterClientiInAttesa();
        this.getCounterByStatus();
        this.setOperatorCompanyActive(JSON.parse(localStorage.getItem("ACTIVE_COMPANY_KEY")));
      }
    );

    this.notAssignedSchedulesSubscription = this.scheduleService.notAssignedSchedulesConfirmed.subscribe(
      result => {
        this.notAssignedSchedulesNumber = result;
      }
    );

    this.abgmagSupscription = this.scheduleService.abgMagInAttesaConfirmed.subscribe(
      result => {
        this.abgInAttesaNumber = result.abg;
        this.magInAttesaNumber = result.mag;
        // localStorage.setItem("abgMagInattesa", JSON.stringify(result));
      }
    );

    // if (localStorage.getItem("abgMagInattesa") != null) {
    //   let obj = JSON.parse(localStorage.getItem("abgMagInattesa"));
    //   this.abgInAttesaNumber = obj.abg;
    //   this.magInAttesaNumber = obj.mag;
    // }

    platform.ready().then(() => {
      audioNotifyService.preload('nuovaScheduleInAttesa', 'assets/audio/alertAvis.mp3');
    });

    this.loaderSubscription = this.utilityService.getStatusLoaderBar().subscribe(loader => {
      setTimeout(() => {
        this.enabledLoadergBar = loader.isEnabled;
      }, 0);
    });
  }

  ngOnInit() {
    if (!this.platform.is('cordova'))
      this.checkForNewVersion();
    this.activeOperator = this.operatorService.getActiveOperator();

    this.counterSubscription = timer(0, 30000)
        .subscribe(() => {
          this.getCounterClientiInAttesa();
          this.getCounterByStatus();
        });
  }


  getCounterByStatus() {
    let obj = {abg: 0, mag: 0};
    if (this.activeOperator != null || this.activeOperator != undefined) {
      let idSR: any = this.myServiceRoom && this.myServiceRoom.id ? this.myServiceRoom.id : "";
      this.companyList.forEach(company => {
        this.scheduleService.countByStatus(company.id).subscribe(count => {
          if (company.code === 'AVIS'){
            this.scheduleService.abgMagInAttesaSource.next({
              abg: count,
              mag: this.magInAttesaNumber
            });
          }
          else if (company.code === 'Maggiore'){
            this.scheduleService.abgMagInAttesaSource.next({
              abg: this.abgInAttesaNumber,
              mag: count
            });
          }
        });
      });

    }
  }

  private getCounterClientiInAttesa() {
    if (this.activeOperator != null || this.activeOperator != undefined) {
      let today = new Date().toISOString().substring(0, 10);
      let serviceRoomId = (this.activeOperator.serviceRoomId) ? this.activeOperator.serviceRoomId : '';
      this.scheduleService.get<number>(GET_COUNT_SCHEDULES_URL + serviceRoomId + "&date=" + today)
          .subscribe(
              result => {
                this.notAssignedSchedulesNumber = result;
              }
          );
    }
  }

  checkForNewVersion() {
    this.versioneApp = environment.APP_VERSION;
    this.deskService.get<GetList<VersionList>>(checkUpdateUrl).subscribe(result => {
      let version = result._embedded.mobileAppVersions.length > 0 ? result._embedded.mobileAppVersions[0] : null;
      if (version && (!this.versioneApp || this.versioneApp < version.versionNumber)) {
        this.presentNewVersionToast();
        // window.location.reload();
      }
    });
  }

  onChangeServiceRoom(serviceRoom: ServiceRoom) {
    this.serviceRoomService.setStoredServiceRoom(serviceRoom);
  }

  async presentNewVersionToast() {
    this.toast = await this.toastController.create({
      message: this.translateService.instant('app-component.È DISPONIBILE UNA NUOVA VERSIONE'),
      position: 'top',
      cssClass: 'toast-controller',
      buttons: [
        {
          text: this.translateService.instant('app-component.RICARICA IL BROWSER'),
          role: 'cancel',
          handler: () => {
            window.location.reload();
          }
        }
      ],

    });
    this.toast.present();
  }

  ionViewDidEnter() {
    $("#header").css("display", "none");
    $("#splitPaneMenu").css("display", "none");
  }

  ionViewDidLeave() {
    if (this.selectCompanySubscription) this.selectCompanySubscription.unsubscribe();
    if (this.authSubscribe) this.authSubscribe.unsubscribe();
    if (this.notAssignedSchedulesSubscription) this.notAssignedSchedulesSubscription.unsubscribe();
    if (this.abgmagSupscription) this.abgmagSupscription.unsubscribe();

    this.scheduleService.stopTimerinAttesaBrandTimer();
    this.loaderSubscription.unsubscribe();
    this.counterSubscription.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
      this.operator = this.operatorService.getActiveOperator();
      if (this.operator != undefined && this.operator.name != "OPERATOR NOT FOUND")
        this.nomeUtente = this.operator.name;
      else {
        let principal = this.principalService.getLoggedPrincipal();
        if (principal != undefined) this.nomeUtente = principal.username;
      }
    });

    this.getCompanyList();


    //refresh
    if (localStorage.getItem("companies") != undefined) {
      this.companyService.setCompanyList(JSON.parse(localStorage.getItem("companies")));
    }
  }

  
  async disconnetti() {
    const modal = await this.modalCtrl.create({
      component: ModaleElementoPendingComponent,
      cssClass: 'disconnetti-modal modal-rounded',
      componentProps: {
        messaggio: this.translateService.instant('app-component.Disconnettere profilo?')
      }
    });
    modal.onDidDismiss().then(result => {
      if (result.data) {
        //logout
        this.deskService.freeDeskAndLogOut();
        this.myDesk = null;
        this.eventsService.publish(EventsService.LOGOUT_EVENT);
      }
    })

    return await modal.present();
  }


  async cambioPass() {
    const modal = await this.modalCtrl.create({
      component: CambiaPassComponent,
      cssClass: 'modal-cambia-pass modal-rounded',
      componentProps: {
        // messaggio: "Caratteri "
      }
    });
    modal.onDidDismiss().then(result => {
      if (result.data) {


      }
    })

    return await modal.present();
  }


  private getCompanyList() {
    this.companyService.getCompanyList().subscribe(companyList => {
      this.companyList = companyList;
    });
  }

  changeCompany(company) {
    localStorage.setItem(Constants.ACTIVE_COMPANY_KEY, JSON.stringify(company));
    this.companyService.selectedCompanySource.next(company.name);
    this.setOperatorCompanyActive(company);
  }

  private setOperatorCompanyActive(company) {
    this.companyService.setOperatorCompanyActive(this.operator?.id, company._links?.self.href)
        .subscribe(response => {});
  }

  existCodeCompany(codeCompanyString: string) {
    if (codeCompanyString && this.companyList){
      return this.companyList.some(arrVal => codeCompanyString === arrVal.code);
    }
  }

  expandMenu() {
    this.menuExpanded = !this.menuExpanded;
  }

  closeMenu() {
    this.menuExpanded = false;
  }

  ngOnDestroy(): void {
    this.scheduleService.stopTimerinAttesaBrandTimer();
    this.loaderSubscription.unsubscribe();
    this.counterSubscription.unsubscribe();
  }

}
