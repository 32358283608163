import { Hateoas } from './hateos';
import { HrefLink } from '../interface/generic-links';
import { Company } from './company';

export class Brand extends Hateoas<BrandLinks>{
    name:string;
    code:string;
    shortCode: string;
    company: Company | string;
}

export interface BrandLinks {
    company: HrefLink;

}

export interface BrandGetList {
    brands:Brand[];
}