import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { timeout } from 'rxjs/operators';
import { Page } from 'src/app/interface/page';
import { Activity } from 'src/app/model/activity';
import { OperatorInactivityEvent } from 'src/app/model/operator-inactivity-event';
import { ActivityService } from 'src/app/services/activity.service';
import { EventsService } from 'src/app/services/events.service';
import { OperatorInactivityService } from 'src/app/services/operator-inactivity.service';

@Component({
  selector: 'app-inactive-operator-popover',
  templateUrl: './inactive-operator-popover.component.html',
  styleUrls: ['./inactive-operator-popover.component.scss'],
})
export class InactiveOperatorPopoverComponent implements OnInit {

  events: OperatorInactivityEvent[] = [];
  serviceRoomId: number;
  isLoading: boolean = true;

  constructor(private operatorInactivityService: OperatorInactivityService, public navParams: NavParams, public activityService: ActivityService, public eventsService: EventsService) {
    this.serviceRoomId = this.navParams.get('serviceRoomId');
    this.getLatestEvents();
  }

  ngOnInit() { }

  getLatestEvents() {
    this.isLoading = true;
    let page = new Page()
    page.size = 20;
    page.number = 0;
    this.operatorInactivityService.findByServiceRoomId(page, this.serviceRoomId).subscribe(oieRes => {
      this.events = oieRes._embedded.operatorInactivityEvents;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.error("InactiveOperatorPopoverComponent - findByServiceRoomId", err);
    })
  }

  read(oie: OperatorInactivityEvent) {
    let update = { read: true }
    this.operatorInactivityService.patchObject(oie._links.self.href, update).subscribe(res => {
      oie.read = true;
      this.eventsService.publish(EventsService.READ_INACTIVITY_EVENT);
    })
  }


}
