import { Hateoas } from './hateos';
import { Reservation } from './reservation';
import { HrefLink } from '../interface/generic-links';
import { TimeSlot } from './time-slot';
import * as moment from 'moment';
import { Desk } from './desk';
import { PickUpPoint } from './pick-up-point';
import { StatoSmartCheckOut } from '../enums/stato-smart-check-out.enum';
import { Brand } from './brand';
import { Operator } from './operator';
import { ConventionProposal } from './convention-proposal';
import {Language} from "./language";

export class Schedule extends Hateoas<ScheduleLinks> {
    priorityLables:String[]=['Alta','Media','Normale'];
    agenda: string;
    reservation: Reservation| any;
    //sarebbe il desck a cui l o schedule è assegnato
    created: Date;
    modified:Date;
    principalCreatorId: string;
    principalModifierId: string;
    assigned: string | Desk;
    appointmentDate: string | Date;
    appointmentTime: string | Date;
    language: Language | any;
    note: string;
    pickUpPoint:PickUpPoint;
    status:StatoSmartCheckOut;
    dateStatusChange: Date;
    //codice identificativo chiamata
    code: string;
    priority: number;
    brand: Brand;
    activeOperator:Operator;
    pickUpCongestion: any;
    conventionProposal: any | ConventionProposal;
// Questo booleano determina la conferma dell'appuntamento da parte dell'operatore (Inserimento appuntamento da gestionale)
// necessario per invio email in lingua BE.
    completed: boolean;
    channel: string;

    // setTimeSlot(timeSlot: TimeSlot) {
    //     this.appointmentDate=timeSlot.date;
    //     this.appointmentTime= timeSlot.time;
    // }
}

export interface ScheduleLinks {
    agenda: HrefLink;
    reservation: HrefLink;
    assigned: HrefLink;
    notes: HrefLink;
    activities: HrefLink;
}

export interface SchedulesGetList {
    schedules: Schedule[];
}