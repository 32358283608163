import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'security/login',
    pathMatch: 'full'
  },
  {
    path: 'security/login',
    loadChildren: () => import('./core/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'insert-appointment',
    loadChildren: () => import('./feature/tabs-uno/tabs-uno.module').then( m => m.TabsUnoPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'calls-management',
    loadChildren: () => import('./feature/tabs-due/tabs-due.module').then( m => m.TabsDuePageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'pup-detail',
    loadChildren: () => import('./feature/tabs-tre/tabs-tre.module').then( m => m.TabsTrePageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'set-active-company',
    loadChildren: () => import('./core/set-active-company/set-active-company.module').then( m => m.SetActiveCompanyPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./feature/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuard]

  },

  {
    path: 'calendar',
    loadChildren: () => import('./feature/tab-calendar2/tab-calendar2.module').then( m => m.TabCalendar2PageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'service-room-dashboard',
    loadChildren: () => import('./feature/dashboard2/dashboard2.module').then( m => m.Dashboard2PageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'settings',
    loadChildren: () => import('./feature/settings/settings.module').then( m => m.SettingsPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'appointments',
    loadChildren: () => import('./feature/tab-appuntamenti-non-ass/tab-appuntamenti-non-ass.module').then( m => m.TabAppuntamentiNonAssPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'customers-waiting',
    loadChildren: () => import('./feature/clienti-in-attesa/clienti-in-attesa.module').then( m => m.ClientiInAttesaPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'preprint',
    loadChildren: () => import('./feature/preprint/preprint.module').then( m => m.PreprintPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'pup-management',
    loadChildren: () => import('./feature/gestione-pup/gestione-pup.module').then( m => m.GestionePupPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'test-ws',
    loadChildren: () => import('./feature/test-ws/test-ws.module').then( m => m.TestWSPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'signature',
    loadChildren: () => import('./feature/firma/firma.module').then( m => m.FirmaPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'conventions',
    loadChildren: () => import('./feature/tabs-conventions/tabs-conventions.module').then( m => m.TabsConventionsPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'check-scheduled-call',
    loadChildren: () => import('./feature/check-scheduled-call/check-scheduled-call.module').then( m => m.CheckScheduledCallPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'tablet-management',
    loadChildren: () => import('./feature/tab-gestione-tablet/tab-gestione-tablet.module').then( m => m.TabGestioneTabletPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'tab-tools',
    loadChildren: () => import('./feature/tab-tools/tab-tools.module').then( m => m.TabToolsPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'tabs-metabase',
    loadChildren: () => import('./feature/tabs-metabase/tabs-metabase.module').then( m => m.TabsMetabasePageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'report-list/link-report',
    loadChildren: () => import('./feature/tabs-metabase/tabs-metabase.module').then( m => m.TabsMetabasePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./feature/configuration/configuration.module').then( m => m.ConfigurationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'report-uso-per-country',
    loadChildren: () => import('./feature/report-uso-per-country/report-uso-per-country.module').then( m => m.ReportUsoPerCountryPageModule),
    canActivate: [AuthGuard]
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
