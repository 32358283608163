import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export enum StatoSmartCheckOut {
	DA_CHIAMARE = "DA_CHIAMARE",
	ON_HOLD = "ON_HOLD",
	COMPLETO = "COMPLETO",
	ANNULLATO = "ANNULLATO",
	IN_ATTESA = "IN_ATTESA",
	PRECARICATO = "PRECARICATO"
}

// metodo per aggiungere gli enum al json di traduzione
marker('enum.StatoSmartCheckOut.DA_CHIAMARE');
marker('enum.StatoSmartCheckOut.ON_HOLD');
marker('enum.StatoSmartCheckOut.COMPLETO');
marker('enum.StatoSmartCheckOut.ANNULLATO');
marker('enum.StatoSmartCheckOut.IN_ATTESA');
marker('enum.StatoSmartCheckOut.PRECARICATO');