import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HateoasService } from 'src/app/services/hateoas.service';
import { Schedule } from 'src/app/model/schedule';

@Component({
  selector: 'app-priorita-schedule',
  templateUrl: './priorita-schedule.component.html',
  styleUrls: ['./priorita-schedule.component.scss'],
})
export class PrioritaScheduleComponent implements OnInit {
  @Input() schedule: Schedule;
  @Output() prioritized = new EventEmitter<number>();
  scheduleType = "normale";
  selected = true;

  constructor(private hateoasService: HateoasService) { }

  ngOnInit() {
    if (this.schedule && this.schedule.priority) {
      switch (this.schedule.priority) {
        case 2:
          this.scheduleType = "media";
          break;
        case 3:
          this.scheduleType = "alta";
          break;

        default:
          break;
      }
    }
  }

  updatePriority(priority: number) {
    if (this.schedule && this.schedule._links?.self)
      this.hateoasService.patchObject(this.schedule._links.self.href, { priority: priority }).subscribe(res => {
      })
  }

  onRadioChange(value) {
    let priority = 1
    if (value == "media") {
      priority = 2;
    } else if (value == "alta") {
      priority = 3;
    }
    this.scheduleType = value;
    this.prioritized.emit(priority);
    this.updatePriority(priority);
  }
}
