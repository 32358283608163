import { Hateoas } from './hateos';
import { HrefLink } from '../interface/generic-links';
import { ServiceRoom } from './service-room';

export class Area extends Hateoas<AreaLinks> {
    name: string;
    descrizione: string;
    serviceRoom: any | ServiceRoom;
    code:string;

}

export interface AreaLinks {
    pickUpPoints: HrefLink;
    serviceRoom: HrefLink;
}

export interface AreaGetList {
    areas: Area[];
}