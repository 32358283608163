import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { DeviceHexnodeData } from 'src/app/model/device-hexnode-data';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {

  map: Leaflet.Map;
  @Input() markers: DeviceHexnodeData[] = [];

  constructor() { }

  ngOnInit() {
    if (!this.markers.includes(null)) {
      this.initializeMap();
    }
  }

  initializeMap() {
    this.map = Leaflet.map('mapId').setView([43.7338657,3.549866], 5);
    Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'edupala.com © Angular LeafLet',
    }).addTo(this.map);

    for(let i = 0; i < this.markers.length; i++) {
      Leaflet.marker([this.markers[i].latitude, this.markers[i].longitude]).addTo(this.map).bindPopup(this.markers[i].last_location);
    }
    this.map.panTo([this.markers[0].latitude, this.markers[0].longitude]);

    setTimeout(() => {
      this.map.invalidateSize();
    }, 0);
  }

  ngOnDestroy() {
    if (!this.markers.includes(null)) {
      this.map.remove();
    }
  }

}
