import { Component, OnInit, Input } from '@angular/core';
import { Activity, ActivitiesGetList } from 'src/app/model/activity';
import { ActivityService } from 'src/app/services/activity.service';
import { GetList } from 'src/app/interface/generic-links';
import { Schedule } from 'src/app/model/schedule';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/interface/page';

const baseUrl=`${environment.endpointUri}/operatorActivities/search/findByScheduleId?sort=created,desc&scheduleId=`

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent implements OnInit {

  @Input() schedule:Schedule; 
  
  activities: Array<Activity> = new Array();

  getUrl:string;

  lastcloseEvent:Activity;
  lastCloseEventId: number;

  page:Page;
  isLoading: boolean;
  disableBtn: boolean = true;

  constructor(private service:ActivityService) { }

  ngOnInit() {
    this.schedule= new Schedule(this.schedule);
    this.getUrl=baseUrl+this.schedule.getIdBySelf();
    this.getActivities();
    
  }

  activityFilter(activity:Activity, index:number, activities:Array<Activity>) {
    if(activity.note!="schedule opened") 
       return true;
     else 
      return false;
  }

  setDuration(activities:Activity[]) {
    // Dovrei filtrare qui ma non ho tempo
    let results:Array<Activity> = new Array();
    let lastcloseEvent;
    let lastCloseEventId:number;
    for(let i=0; i< activities.length; i++) {
      if(activities[i].note!="schedule opened") {
        lastcloseEvent=activities[i];
        lastCloseEventId=i;
      } else {
        if(lastcloseEvent){
          lastcloseEvent.duration= new Date();
          let start= new Date(activities[i].created)  ;
          let end = new Date(lastcloseEvent.created);
          lastcloseEvent.duration.setTime(Math.abs(end.getTime()- start.getTime()));
          activities[lastCloseEventId]=lastcloseEvent;
        }
      }
    }
  }

  getActivities(event?) {
    let pageP='';
    if(this.page) 
      pageP="&"+this.page.getPageQueryParams();
     this.isLoading=true; 
    this.service.get<GetList<ActivitiesGetList>>(this.getUrl+pageP).subscribe(
      result => {
        this.setDuration(result._embedded.operatorActivities);
        let tmp:Activity[] = result._embedded.operatorActivities.filter(this.activityFilter,this);
        tmp.forEach(element => {
          this.activities.push(element);
        });
        this.page=new Page(result.page);
        
        this.disableBtn=!this.page.hasMoreResults();
        if(event)
          event.target.complete();
        this.isLoading=false;
      }
    );
  }

  doMyInfinite(event) {
    if(this.page){
      if(this.page.hasMoreResults())
        this.page.nextPage()
      else  
        this.disableBtn=true;
    }
    if(!this.isLoading)
      this.getActivities();
  }

  doInfinite(event) {
    if(this.page){
      if(this.page.hasMoreResults())
        this.page.nextPage()
      else
        event.target.complete();
    }
    if(!this.isLoading)
      this.getActivities(event);
  }

}
