import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Area, AreaGetList } from 'src/app/model/area';
import { AreaService } from 'src/app/services/area.service';
// import { GetList } from 'src/app/interface/generic-links';
// import { environment } from 'src/environments/environment';

// const AREA_BASE_URL=`${environment.endpointUri}/areas?projection=AreaData`;

@Component({
  selector: 'app-area-select',
  templateUrl: './area-select.component.html',
  styleUrls: ['./area-select.component.scss'],
})
export class AreaSelectComponent implements OnInit {

  @Input() redTheme: boolean = false;
  @Input() areas: Array<Area> = new Array<Area>();
  
  @Input() showArea: boolean;

  @Input() value = new Area();

  @Output() selected = new EventEmitter<Area>();

  text:String='';

  @Input() openedFromModal: boolean = false;

  constructor(private service: AreaService) { }

  ngOnInit() {
    // this.loadAreas();
  }

  loadAreas() {
    // this.service.get<GetList<AreaGetList>>(AREA_BASE_URL).subscribe(
    //   result => {
    //     this.areas=result._embedded.areas;
    //   }
    // )
  }

  onChange(event) {

    this.selected.emit(event.target.value);
  }

  compareWithFn = (o1, o2) => {
    let a1 = new Area(o1);
    let a2 = new Area(o2);
    return o1 && o2 ? a1.getIdBySelf() === a2.getIdBySelf() : o1 === o2;
  };

  compareWith = this.compareWithFn;

  
}
