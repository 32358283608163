import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ScheduleNote } from 'src/app/model/schedule-note';
import { HateoasService } from 'src/app/services/hateoas.service';
import { Note } from 'src/app/model/note';
import { ModalController } from '@ionic/angular';
import { ThrowStmt } from '@angular/compiler';
import { ModaleRemoveNoteComponent } from '../modale-remove-note/modale-remove-note.component';
import { PrincipalService } from 'dst-security';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})

export class NoteComponent implements OnInit {

  noteText: string;
  clickedNote: boolean = false;
  showClickedNote: boolean[] = [];
  @Input() noteList: ScheduleNote[];
  pulisciNote: string = '';
  @Output() onNoteEmitted: EventEmitter<string> = new EventEmitter<string>();
  @Output() onNoteDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private hs: HateoasService,
    private modalCtrl: ModalController,
   public principalService: PrincipalService

  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.noteList && changes.noteList.previousValue != changes.noteList.currentValue) {
      this.noteList = changes.noteList.currentValue;
      for (let i: number = 0; i < this.noteList.length; i++) {
        this.showClickedNote.push(false);
      }
    }
  }

  updateNote(text) {
    this.noteText = text.detail.value;
  }

  addNote() {

    if (this.pulisciNote != undefined && this.pulisciNote != '') {
      this.showClickedNote.push(false);
      this.onNoteEmitted.emit(this.noteText);
      this.pulisciNote = '';
    }
  }

  onNoteClick() {
    this.clickedNote = !this.clickedNote;
  }

  showNote(i: number) {
    this.showClickedNote[i] = !this.showClickedNote[i];
  }

  async openModalRemoveNote(nota: Note) {
    const modal = await this.modalCtrl.create({
      component: ModaleRemoveNoteComponent,
      cssClass: 'pending-modal',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then(result => {
      if (result.data != undefined && result.data.confirm == true) {
        this.hs.deleteObject(nota._links.self.href).subscribe(res => {
          this.onNoteDeleted.emit(true);
        });
      }
    });
    return await modal.present();
  }

}
