import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/model/company';
import { Brand, BrandGetList } from 'src/app/model/brand';
import { environment } from 'src/environments/environment';
import { GetList } from 'src/app/interface/generic-links';
import { Subscription } from 'rxjs';

const BRAND_BASE_URL = `${environment.endpointUri}/brands`;

@Component({
  selector: 'app-select-brand',
  templateUrl: './select-brand.component.html',
  styleUrls: ['./select-brand.component.scss'],
})
export class SelectBrandComponent implements OnInit, OnChanges, OnDestroy {

  @Output() selected = new EventEmitter<Brand>();

  @Input() roundSelect: boolean = true;
  @Input() callPageStyle: boolean = false;
  @Input() redTheme: boolean = false;
  @Input() disabled: boolean = false;
  @Input() value = new Brand();
  //nuova property per sezioni in cui è possibile selezionare tutti i brand
  @Input() allBrands: boolean = false;
  @Input() multiSelect: boolean = false;
  brands: Array<Brand> = new Array();
  company: Company;
  companySub: Subscription = new Subscription();
  constructor(private service: CompanyService) {

   }

  ngOnInit() {
    this.companySub = this.service.selectedCompanyConfirmed.subscribe( res => {
      this.getBrands();
    });
    this.getBrands();
  }

  getBrands() {
    this.company = this.service.getActiveCompany();
    let url: string = "";
    if (this.allBrands) {
      url = `${BRAND_BASE_URL}?projection=BrandCompany`;
    }
    else {
      url = `${BRAND_BASE_URL}/search/findByCompanyId?companyId=${this.company.id}&projection=BrandCompany`;
    }
    this.service.get<GetList<BrandGetList>>(url).subscribe(
      results => {
        console.log('this.value', this.value);
        this.brands = results._embedded.brands;
        //if (this.value) {
        //  let i = this.brands.findIndex(x => x.id == this.value.id);
        //  if (i > -1) this.value = this.brands[i];
        //}
      }
    );
  }


  ngOnChanges(changes) {
  }

  /** martina - non credo entri mai in questo metodo dato che si tratta di un componente,
   * solo le page vedono i metodi del life cycle di ionic
   */
  ionViewDidEnter() {

    //CHIEDERE A PAOLO PERCHè HA FATTO COSI
    this.service.get<GetList<BrandGetList>>(BRAND_BASE_URL).subscribe(
      results => {
        this.brands = results._embedded.brands;
      }
    );
  }

  onChange(value) {
    this.selected.emit(value);
  }

  ngOnDestroy() {
    this.companySub.unsubscribe();
  }

  compareWithFn = (o1, o2) => {
    let brand1 = new Brand(o1);
    let brand2 = new Brand(o2);
    return o1 && o2 ? brand1.getIdBySelf() === brand2.getIdBySelf() : o1 === o2;
  };

  compareWith = this.compareWithFn;


}
