import { Injectable } from '@angular/core';
import { HateoasService } from './hateoas.service';
import { HttpClient } from '@angular/common/http';
import { Schedule } from '../model/schedule';
import { Activity } from '../model/activity';
import { environment } from 'src/environments/environment';
import { OperatorService } from './operator.service';

const ACTIVITY_BASE_URL = `${environment.endpointUri}/operatorActivities`

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends HateoasService {

  constructor(http: HttpClient, private operatorService: OperatorService) {
    super(http)
  }

  saveActivity(action: string, schedule: Schedule) {
    let activity = new Activity();
    activity.note = action;
    activity.schedule = schedule._links.self.href;
    activity.operator = this.operatorService.getActiveOperator()._links.self.href;
    return this.createObject(ACTIVITY_BASE_URL, activity);
  }
}
