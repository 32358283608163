import { Component, OnInit, Input } from '@angular/core';
import { Activity } from 'src/app/model/activity';

@Component({
  selector: 'app-dettaglio-call',
  templateUrl: './dettaglio-call.component.html',
  styleUrls: ['./dettaglio-call.component.scss'],
})
export class DettaglioCallComponent implements OnInit {

  @Input() activity:Activity;

  constructor() { }

  ngOnInit() {}

}
