import { Injectable, Injector } from '@angular/core';
import { HandleErrorServiceService } from 'dst-security';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { ShUserNotifyService } from './sh-user-notify.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ErrorCode } from '../enums/error-code.enum';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService  extends HandleErrorServiceService {

  translateService;

  constructor(public snackBar: MatSnackBar, private shUserNotifyService : ShUserNotifyService, private injector: Injector) {
    super();
  }
  
  handleError(err: HttpErrorResponse) {
    this.translateService = this.injector.get(TranslateService);
    if (err.url != `${environment.endpointUri}/api/desks/search/getMyDesk` && err.status != 404) {
      console.log(err);
      if (err.status == 401) {
        this.shUserNotifyService.notifyError(this.translateService.instant("enum.ErrorCode.E000401"));
      }
      else if(err.error && err.error.message) {
        if (ErrorCode[err.error.message]) {
          this.shUserNotifyService.notifyError(this.translateService.instant(`enum.ErrorCode.${err.error.message}`));  
        }
        else {
          this.shUserNotifyService.notifyError(`${err.error.code}: ${err.error.message}`);  
        }
      }
      else {
        if (ErrorCode[err.message]) {
          this.shUserNotifyService.notifyError(this.translateService.instant(`enum.ErrorCode.${err.message}`));
        }
        else {
          this.shUserNotifyService.notifyError(`${err.status}: ${err.message}`);
        }
      }
      super.handleError(err);
    }
  }
}