// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {globalEnvironment} from './global-enviroment';
import {LanguageEnum} from '../app/enums/language.enum';

export const environment = {
  production: false,
  APP_VERSION: globalEnvironment.APP_VERSION,
  CHANNEL_NAME: globalEnvironment.CHANNEL_NAME,
  // endpointUri: 'http://localhost:8081/api',
  // endpointUri: 'https://smart-checkout.dstech.info/api',
  endpointUri: 'https://smart-checkout-test.dstech.info/api',
  // endpointUri: 'https://smart-checkout-test-es.dstech.info/api',
  // endpointUri: 'https://smart-checkout-uat-es.dstech.info/api',
  DEFAULT_LANGUAGE: LanguageEnum.ENGLISH,
  ACTIVE_LANGUAGE: [LanguageEnum.ENGLISH, LanguageEnum.ITALIANO],
  operatorViewAnyServiceRoom: false,
  generateQrCodeButtonEnabled: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
