import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { HateoasService } from 'src/app/services/hateoas.service';
import { environment } from 'src/environments/environment';
import { CompanyGetList, Company } from 'src/app/model/company';
import { GetList } from 'src/app/interface/generic-links';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const COMPANY_PRIVATE_URL=`${environment.endpointUri}/companies`;
const COMPANY_PUBLIC_URL=`${environment.endpointUri}/bookAppointment/getCompanies`;

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
})
export class CompanySelectComponent implements OnInit {

  companies: Array<Company> = new Array<Company>();

  @Input() redTheme: boolean = false;
  @Input() loginPage: boolean = false;
  @Input() selectStyle: boolean = true;
  @Output() selected = new EventEmitter<Company>();
  @Output() companiesToEmit: EventEmitter<Company[]> = new EventEmitter<Company[]>();
  @Input() value= new Company();
  @Input() disabled = false;

  constructor(private service: HateoasService) { }

  ngOnInit() {
    this.loadCompanies();
  }

  loadCompanies() {
    this.service.get<any>(this.loginPage ? COMPANY_PUBLIC_URL : COMPANY_PRIVATE_URL).subscribe(
      result => {
        if (this.loginPage) {
          this.companies = result;
          this.companies.forEach(element => {
            element["_links"] = {
              self: {href: `${COMPANY_PRIVATE_URL}/${element.id}`}
            };
          });
        }
        else {
          this.companies = result._embedded.companies;
        }
        this.companiesToEmit.emit(this.companies);
      }
    )
  }

  onChange(value) {
    this.selected.emit(value);
    this.value=value;
  }

  compareWithFn = (o1, o2) => {
    let a1 = new Company(o1);
    let a2 = new Company(o2);
    return o1 && o2 ? a1.id === a2.id : o1 === o2;
  };

  compareWith = this.compareWithFn;



}
