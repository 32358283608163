import {LOCALE_ID, NgModule} from '@angular/core';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {DstSecurityModule, UserNotifyService, HandleErrorServiceService} from 'dst-security';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {HttpClient, HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ConstantsEndpoint} from './constantsEndpoint';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CustomInterceptorService} from './services/custom-interceptor.service';
import {CustomSnackbarComponent} from './shared/custom-snackbar/custom-snackbar.component';
import {ShUserNotifyService} from './services/sh-user-notify.service';
import {HandleErrorService} from './services/handle-error.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ModaleElementoPendingComponent} from './feature/modal/modale-elemento-pending/modale-elemento-pending.component';
import {DatePipe} from '@angular/common';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {NativeAudio} from '@ionic-native/native-audio/ngx';
import {AudioNotifyService} from './services/audio-notify.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {InactiveOperatorBadgeComponent} from './shared/components/inactive-operator-badge/inactive-operator-badge.component';
import {InactiveOperatorPopoverComponent} from './shared/components/inactive-operator-popover/inactive-operator-popover.component';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CustomDateAdapter} from './util/custom-date-adapter';
import {SharedModule} from './shared/shared.module';
import {TranslateModule, TranslateLoader, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        CustomSnackbarComponent,
        ModaleElementoPendingComponent,
        InactiveOperatorBadgeComponent,
        InactiveOperatorPopoverComponent,
    ],
    entryComponents: [CustomSnackbarComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        MatSidenavModule,
        MatExpansionModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.DEFAULT_LANGUAGE,
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        DstSecurityModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        SharedModule,
        MatProgressBarModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AudioNotifyService,
        NativeAudio,
        DatePipe,
        AppVersion,
        InAppBrowser,
        // {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: 'environment', useValue: ConstantsEndpoint},
        {provide: 'environmentMetabase', useValue: environment},
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: InterceptorService,
        //   multi: true
        // },
        {provide: UserNotifyService, useClass: ShUserNotifyService},
        {provide: HandleErrorServiceService, useClass: HandleErrorService},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomInterceptorService,
            multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: environment.DEFAULT_LANGUAGE},
        {provide: LOCALE_ID, useValue: environment.DEFAULT_LANGUAGE},
        {provide: DateAdapter, useClass: CustomDateAdapter},
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}
