import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickUpPointSelectComponent } from './components/pick-up-point-select/pick-up-point-select.component';
import { AreaSelectComponent } from './components/area-select/area-select.component';
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { NoteComponent } from './components/custom-note/note.component';
import { MatIconModule } from '@angular/material/icon';
import { DettaglioCallComponent } from './components/dettaglio-call/dettaglio-call.component';
import { ActivityListComponent } from './components/activity-list/activity-list.component';
import { IonicModule } from '@ionic/angular';
import { PrioritaScheduleComponent } from './components/priorita-schedule/priorita-schedule.component';
import { SelectBrandComponent } from './components/select-brand/select-brand.component';
import { ModaleRemoveNoteComponent } from './components/modale-remove-note/modale-remove-note.component';
import { ServiceRoomSelectComponent } from './components/service-room-select/service-room-select.component';
import { ProfileSelectComponent } from './components/profile-select/profile-select.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {createTranslateLoader} from '../app.module';
import {HttpClient} from '@angular/common/http';
import {LanguageToolbarComponent} from './components/language-toolbar/language-toolbar.component';
import { LanguageSelectComponent } from './components/language-select/language-select.component';
import { MapComponent } from './components/map/map.component';
import { MatSelectModule } from '@angular/material/select';
import {ModalFromToDateComponent} from './components/modal-from-to-date/modal-from-to-date.component';
import {PriorityDotComponent} from "./components/priority-dot/priority-dot.component";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [
        AreaSelectComponent,
        CompanySelectComponent,
        NoteComponent,
        DettaglioCallComponent,
        ActivityListComponent,
        PrioritaScheduleComponent,
        ModaleRemoveNoteComponent,
        SelectBrandComponent,
        PickUpPointSelectComponent,
        ServiceRoomSelectComponent,
        ProfileSelectComponent,
        LanguageToolbarComponent,
        LanguageSelectComponent,
        MapComponent,
        ModalFromToDateComponent,
        PriorityDotComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    //TranslateModule.forChild({
    //  loader: {
    //    provide: TranslateLoader,
    //    useFactory: (createTranslateLoader),
    //    deps: [HttpClient]
    //  }
    //}),
  ],
    exports: [
        AreaSelectComponent,
        CompanySelectComponent,
        NoteComponent,
        DettaglioCallComponent,
        ActivityListComponent,
        PrioritaScheduleComponent,
        SelectBrandComponent,
        PickUpPointSelectComponent,
        ServiceRoomSelectComponent,
        ProfileSelectComponent,
        LanguageToolbarComponent,
        LanguageSelectComponent,
        MapComponent,
        TranslateModule,
        PriorityDotComponent
    ]
})
export class SharedModule { }
